.frame-temp{
  /* width: 384px;
  height: 512px; */
  width: 0px;
  /* border: 1px solid black; */
}

/* img {
image-rendering: crisp-edges;
} */

.display--flex{
  display: flex;
}

.main_container{
  flex-wrap: nowrap;
  padding: 0px 0px 40px 10px;
  flex-direction: row;
}

.button--height{
  height: 40px;
}

.main_container--width{
  width: 360px;
}

.margin--10px{
margin: 10px 10px;
}

.margin_right{
width: 140px;
}

.container{
position: relative;
width: 600px;
height: 800px;
}

.item{
position: absolute;
}

.action-area--container{
  padding: 20px 20px 0px 20px;
}

.radio--color{
  background-color: #da9c40;
}

.margin_left--10px{
  margin-left: 10px;
}

.margin_right--10px{
  margin-right: 10px;
}

.next--margin{
  margin: 0px 0px 0px 40px;
}

.width--380p{
  width: 380px;
}

.flex-wrap--wrap{
  flex-wrap: wrap
} 

.justify-content--center{
  justify-content: center;
}

.margin-top--20p{
  margin-top: 20px;
}

.display--none{
  display: none;
}

.pred_after_work--text{
  padding-top: 405px;
  width: 250px;
}

.person-image--img{
  position: absolute;
  top: 500px;
  margin-left: -125px;
}

.assign-button{
  height: 40px;
  line-height: 40px;
  width: 60%;
}

.live-preview{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
}